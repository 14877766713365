@import "../../Vars";

.SelfieApp-carema-view{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height:100%;
    flex:1;
    min-height: 500px;

    @media only screen and (min-width: 768px) {
        max-width: 700px;
    }

    video,
    canvas{
        width: 100%;
        height:100%;
        flex:1;
        object-fit: cover;
    }
    &__instruction{
        padding:15px;
    }
    &__wrapper{
        position: relative;
        flex:1;
        display: block;
        height: 100%;
        width: 100%;
    }
}

.backBtn{
    padding: 20px;
    margin-bottom:20px;
    text-decoration: none;
    color:black;
    display:block;
}