@import "../../Vars";@import "../../Vars";

.SelfieApp-carema-view{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height:100%;
    flex:1;
    min-height: 500px;

    @media only screen and (min-width: 768px) {
        max-width: 700px;
    }

    video,
    canvas,
    .idimg{
        width: 100%;
        height:100%;
        flex:1;
        object-fit: cover;
    }
    video{
        @media only screen and (max-width: 768px) {
            position: absolute;
        }
    }
    .idimg{
        @media only screen and (max-width: 768px) {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &__instruction{
        padding:15px;
    }
    &__wrapper{
        position: relative;
        flex:1;
        display: block;
        height: 100%;
        width: 100%;
    }
    footer{
        padding: 30px;
        width:100%;
        text-align:center;
    }
}